@use "variables";

.sd-faqs-conatainer {
  font-family: "Proxima Nova Alt", sans-serif;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 30px;

  .sd-border {
    width: 91%;
    border-top: 1px solid #efefef !important;
    text-align: -webkit-center;
    display: flex;
    height: 1px;
  }
  .sd-faqs-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px 40px;
    width: 100%;

    h1 {
      font-size: 30px;
      color: variables.$headingTextColor;
      padding-bottom: 10px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
    img {
      height: 20px;
    }
  }
  .sd-faqs-bottom {
    padding: 0px 60px;
    width: 100%;
    .accordion {
      .selective-card {
        border-right: none;
        border-left: none;
        background-color: white;
        border-bottom: 1.5px solid #efefef;
        .heading {
          padding: 20px 0px;
          background-color: white;
          border-bottom: none;
          .top-head {
            align-items: center;
            justify-content: space-between;
            display: flex;

            .top-head-top {
              display: flex;
              align-items: center;
              h3 {
                text-align: center;
                color: variables.$headingTextColor;
                border-radius: 50%;
                margin: 0 14px 0 0;
                width: 26px;
                font-size: 18px;
                font-weight: 500;
                border: 1px solid variables.$headingTextColor;
                padding: 2px 8px;
                display: flex;
                cursor: pointer;

                justify-content: center;
              }
              h2 {
                margin-right: 10px;
                font-size: 18px;

                letter-spacing: 0.2px;
                font-weight: 600;
                margin: 0px;
                cursor: pointer;
              }
            }
            .custom-h2 {
              margin: 0px;
              .cutombuttom {
                outline: none;
                box-shadow: none;
                border: none;
                display: flex;
                justify-content: center;
                height: 20px;

                img {
                  width: 20px;
                  // font-weight: 700 !important;
                  // font-size: 18px !important;
                  // color: #999;
                }
                // i {
                //   font-size: 18px;
                //   filter: invert(0.5);
                // }
              }
            }
          }
        }
        .sd-display {
          display: block;
        }
        .sd-none {
          display: none;
          height: 0px;
        }
        .card-body {
          font-size: 18px;
          margin-top: -20px;
          padding: 10px 38px 20px;
          transition: height ease-in-out 1s;
          overflow: hidden;
        }
      }
      .sd-first-b {
        border-top: 1.5px solid #efefef;
      }
      .b-none {
        border-top: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
      }
    }
  }

  .sd-faqs-button {
    display: flex;
    justify-content: center;
    .sd-view-button {
      white-space: nowrap;

      margin-top: 20px;
      border: none;
      background-color: variables.$headingTextColor;
      border-radius: 10px;
      color: white;
      font-size: 14px;
      padding: 6px 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      align-self: center;

      img {
        height: 18px;
        margin-left: 8px;
      }
    }
  }
  .sd-bt {
    border-top: 1.5px solid #efefef;
  }
}

@keyframes slow {
  0% {
    opacity: 0;
    height: 0%;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
.sd-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  h1 {
    font-size: 30px;

    padding-bottom: 10px;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    span {
      font-weight: 600;
    }
  }
  img {
    height: 10px;
  }
  padding: 0px 40px 30px 30px;
}
.small-divs-main {
  display: flex;
  justify-content: center;
  padding: 20px 60px 60px 60px;
  flex-wrap: wrap;

  .small-divs {
    cursor: pointer;
    padding: 6px 13px;
    margin: 5px;
    border: 1px solid #2f3193;
    border-radius: 3px;
    color: #181818;
    font-size: 15px;
  }
  .selected {
    background-color: #c3c4fb;
  }
}

@media screen and (max-width: 650px) {
  .sd-faqs-conatainer {
    .sd-faqs-top {
      padding: 20px;
    }
    .sd-faqs-bottom {
      padding: 10px 30px 0px 30px;
      .accordion {
        .selective-card {
          .heading {
            .top-head {
              .top-head-top {
                h3 {
                  font-size: 14px;
                  width: 22px;
                  padding: 2px 5px;
                }
                h2 {
                  font-size: 15px;
                }
              }
              .custom-h2 {
                .cutombuttom {
                  img {
                    width: 15px;
                  }
                }
              }
            }
          }
          .card-body {
            font-size: 15px;
            padding-left: 34px;
          }
        }
      }
    }
  }
  .small-divs-main {
    padding: 10px 30px 30px 30px;

    .small-divs {
      text-align: center;
    }
  }
}
