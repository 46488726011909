/* -----navbar----- */
$baseColor: var(--baseColor);
$secondaryColor: var(--secondaryColor);
$topNavTextColor: var(--topNavTextColor);
$bottomNavtextColor: var(--bottomNavtextColor);
$bottomNavInnerTextColor: var(--bottomNavInnerTextColor);
$bottomNavInnerTextColor: var(--bottomNavInnerTextColor);
$navElementBorderColor: var(--navElementBorderColor);
$navElementHoverColor: var(--navElementHoverColor);
$mobileNavElementBackground: var(--mobileNavElementBackground);
$mobileNavInnerElementBackground: var(--mobileNavInnerElementBackground);
$borderDividerColor: var(--borderDividerColor);

/* -----slider----- */
$baseDotColor: var(--baseDotColor);
$activeDotColor: var(--activeDotColor);

/* ------home/basic page button----------- */
$buttonBackgroundColor: var(--buttonBackgroundColor);
$buttonHoverBackgroundColor: var(--buttonHoverBackgroundColor);
$buttonHoverTextColor: var(--buttonHoverTextColor);
$buttonTextColor: var(--buttonTextColor);

/* breadcrumb */
$breadcrumbTextColor: var(--breadcrumbTextColor);
$headingsColor: var(--headingsColor);

/* -----home page variables ---- */
$servicesBackground: var(--servicesBackground);
$happeningsBackground: var(--happeningsBackground);
$headingTextColor: var(--headingTextColor);
$newsBarColor: var(--newsBarColor);

/* -----footer varibales---- */
$footerTopBackground: var(--footerTopBackground);
$footerMiddleBackground: var(--footerMiddleBackground);
$footerBottomBackground: var(--footerBottomBackground);
