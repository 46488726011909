* {
  padding: 0;
  margin: 0;
}
p,
span,
div {
  cursor: default;
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
.places-div {
  border: 1px solid #cbcbcb;
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
}
.inner-payment {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll; */
  height: 620px;
  text-align: center;
  width: 100%;
}
/* .inner-payment::-webkit-scrollbar {
  display: none;
} */
.places-div h2 {
  color: #991403;
  padding-bottom: 10px;
  border-bottom: 1px solid #cbcbcb;
  font-size: 24px;
}
.ccavenue {
  overflow: scroll;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  padding: 20px;
  width: 630px;
}
.places-div p {
  padding-top: 10px;
}
.temple-select {
  width: 100%;
  display: flex;
  justify-content: center;
}
.temple-select .form-control {
  width: 500px !important;
}
.general-info ul {
  list-style: disc;
  margin-left: 15px;
  font-size: 15px;
}
.general-info p {
  margin-left: 0px;
}
body {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  font-family: "Proxima Nova Alt", sans-serif !important;
  line-height: 1.625em;
  position: relative;
}
/* .mt-60 {
  margin-top: 44px !important;
} */
hr {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mr-35 {
  margin-right: 48px;
}
select {
  display: block;
}

.display-inline {
  display: inline;
}
/* .react-tel-input {
  margin-bottom: 10px;
  box-shadow: none;
} */
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.d-EM {
  position: absolute;
  bottom: -24px;
  font-size: 14px;
  right: 0;
  text-align: right;
  margin-bottom: 0;
  color: red;
}
.react-tel-input input {
  padding: 0px;
  margin-bottom: 0px !important;
  width: 100% !important;
  box-shadow: none;
  height: 42px !important;
  padding: 18.5px 14px 18.5px 60px;
}
.react-tel-input .selected-flag.open:after .selected-flag.open:before {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: none !important;
}
.react-tel-input .selected-flag:focus:after,
.react-tel-input .selected-flag.open:after {
  box-shadow: none !important;
  border: none !important;
}
.react-tel-input .country-list {
  margin: 2px 0px 0px 0px;
  border-radius: 3px;
  border: 0.5px solid #efefef;
}
.react-tel-input .form-control input {
  width: 100%;
  height: 42px !important;
  box-shadow: none;
}
.react-tel-input .form-control:focus {
  box-shadow: none !important;
}
.ani {
  margin-top: 50px !important;
  animation: up-extra 0.1s !important;
}
.sd-phone-voilet {
  border: 1px solid #2f3293;
  border-radius: 4px;
  height: 44px;
  box-shadow: none;
  outline: none;
}
.sd-phone-voilet input:hover {
  border: none !important;
  box-shadow: none;
}

.sd-acc-calender .react-datepicker {
  border: 0.5px solid #cbcbcb;
  width: 100%;
  min-height: 275px;
  min-width: 500px;
  padding-bottom: 10px;
}
.sd-acc-main {
  min-width: 500px;
}
.sd-acc-sub {
}
.sd-acc-calender .react-datepicker .react-datepicker__month-container {
  width: 50%;
  height: 100%;
}
/* .react-datepicker__month {
  height: 100%;
} */
.react-datepicker__header .react-datepicker__current-month {
  font-size: 14px;
}
.react-datepicker__day-names {
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}
.react-datepicker__day--outside-month {
  background-color: transparent !important;
}
.react-datepicker__current-month,
.react-datepicker-year-header {
  font-size: 14px;
  margin-bottom: 10px;
}
.sd-q-label {
  border: 1px solid #2f3193 !important;
  height: fit-content !important;
  font-size: 17px !important;
}
.sd-quota {
  display: flex;
  padding: 6px 12px;
  width: 100%;
  justify-content: center;
}
.sd-quota-div {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px !important;
  width: auto !important;
}
.sd-quota-div p {
  width: 10px;
  height: 10px;
  margin: 0px;
  margin-right: 10px;
}

.react-datepicker__month .react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 10px;
}
.react-datepicker__month .react-datepicker__week .react-datepicker__day {
  color: #000;
  width: 2.7rem;
  line-height: 3rem;
  text-align: center;
  /* margin: 0.3rem; */
  font-size: 14px;
  padding: 5px 10px;
  display: flex !important;
  justify-content: center !important;
}

.sd-phone-voilet input:focus {
  border: none !important;
  box-shadow: none;
}
.sd-phone-voilet input {
  background: white !important;
  background-color: white !important;
  text-transform: capitalize;
  box-shadow: none;
  outline: none;
  margin-bottom: 0;
}

.sd-red {
  border: 1px solid red !important;
  background-color: white !important;
}
figure {
  margin: 0;
}

.mr-15 {
  margin-right: 17px;
}
.mr-30 {
  margin-right: 30px;
}

a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  max-width: initial;
  outline: 0;
}
.sd-acc-img {
  height: 100%;
  width: auto;
  max-height: 200px;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}

.card-panel {
  margin: 0;
  padding: 60px;
}

.btn i,
.btn-flat i,
.btn-floating i,
.btn-large i {
  font-size: 1em;
  line-height: inherit;
}

.gray-bg {
  background: #f9f9ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  /* margin-bottom: 0 !important;
  margin-top: 0 !important; */
  font-weight: 600;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
  color: #222;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 16px;
}

.h6,
h6 {
  font-size: 14px;
  color: #222;
}

td,
th {
  border-radius: 0;
}

.clear::after,
.clear::before {
  content: " ";
  display: table;
}

.clear::after {
  clear: both;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
}

.fz-16 {
  font-size: 16px;
}

.fz-17 {
  font-size: 17px !important;
}

.fz-18 {
  font-size: 18px;
}

.fz-30 {
  font-size: 30px;
}

.fz-48 {
  font-size: 48px !important;
}

.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}
.sd-spcl-fl {
  display: flex;
}
.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500;
}

.fw700 {
  font-weight: 700 !important;
}

.fsi {
  font-style: italic;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-150 {
  margin-top: 150px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0-i {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-90 {
  padding-left: 90px;
}

.p-40 {
  padding: 40px;
}

.p-20 {
  padding: 20px;
}

.nan-border-none {
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select ::placeholder {
  text-transform: none;
}
input {
  color: #181818;
  font-weight: 400;
}
input::placeholder {
  text-transform: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.float-left {
  float: left;
}

.float-right {
  float: right;
}
.float-right-ellipse {
  padding: 0px 10px !important;
  overflow: hidden;
  white-space: nowrap;
  float: right;

  text-overflow: ellipsis;
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.position-sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
}

p {
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.7em;
  color: #999;
}

.line-height2 {
  line-height: 24px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.ml--15 {
  margin-left: -15px;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    LEFT DASHBOARD CONTENT
----------------------------------------------------- */

.sd-dashboard-profile {
  display: inline-block;
  min-height: 56px;
  display: flex;
  align-items: center;
}
.sd-dashboard-profile .profile {
  background: var(--headingTextColor);
  border-radius: 50%;
  /* padding: 35px; */
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  font-size: 3.5rem;
}
.white-bg {
  background-color: white !important;
  color: var(--headingTextColor) !important;
  height: 50px !important;
  width: 50px !important;
  margin-left: 10px !important;
}
.spec {
  display: flex;
  align-items: center;
}
.white-bg p {
  color: var(--headingTextColor) !important;
  font-size: 25px !important;
}
.c-voilet {
  color: #ffffff !important;
}
.sd-dashboard-profile .profile p {
  display: flex;
  /* flex-direction: column; */
  align-self: center;
  color: white;
  /* text-transform: capitalize; */
  font-size: 29px;
  letter-spacing: 1px;
  place-content: center;
}
.sd-dashboard-profile img {
  height: 56px !important;
  width: auto !important;
}

.sd-heading-18 {
  font-size: 16px;
  padding: 0px 0 0 66px;
  font-weight: 600;
  color: var(--headingTextColor);
  text-transform: capitalize;
}

.sd-dashboard-contact-details {
  background: #feebdb;
  border-radius: 4px;
  padding: 14px;
  margin-top: 30px;
}

.sd-dashboard-contact-details p {
  font-size: 14px;
  color: #181818;
  line-height: 16px;
  font-weight: 400;
}

.sd-phone {
  margin-bottom: 0px;
  padding-top: 7px;
}

.sd-logout-img {
  height: 15px !important;
}

.sd-dashboard-side-menu li.active a {
  color: var(--headingTextColor) !important;
}
.sd-dashboard-side-menu li a {
  color: #181818;
}

.sd-dashboard-side-menu button.collapsible {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  background: none;
}

.sd-dashboard-side-menu button.collapsible:after {
  content: "\002B";
  float: right;
  margin-left: 5px;
  border-radius: 50%;
  text-align: center;
  color: var(--headingTextColor);
  font-weight: 600;
  cursor: pointer;

  margin-top: 5px;
  border: 1.6px solid #2f3193;
  width: 16px;
  height: 16px;
  line-height: 11px;
  display: none;
}
.sd-plus-icon {
  content: "\002B";
  float: right;
  margin-left: 5px;
  border-radius: 50%;
  text-align: center;
  color: var(--headingTextColor);
  font-weight: 600;
  margin-top: 5px;
  border: 1.6px solid var(--headingTextColor);
  width: 16px;
  height: 16px;
  line-height: 11px;
}

.sd-dashboard-side-menu button.active:after {
  content: "\2212";
}

.sd-dashboard-side-menu .content {
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.content ul.sd-dashboard-side-menu-sub {
  margin-top: 5px;
  padding: 1px 0px 0px 29px;
}

.sd-phone a span {
  font-size: 13px;
  color: var(--headingTextColor);
  font-weight: 500;
}

.sd-phone img {
  height: 15px;
  margin-top: -3px;
  margin-right: 4px;
}

.sd-dashboard-side-menu {
  padding-top: 10px;
}

.sd-dashboard-side-menu li {
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 400;
  cursor: pointer;
  color: #181818 !important;
}

.sd-dashboard-side-menu li .content ul.sd-dashboard-side-menu-sub li {
  font-size: 14px;
  cursor: pointer;
  margin: 6px 0px 0px;
}

.sd-dashboard-side-menu li img {
  height: 17px;
  margin-right: 8px;
  cursor: pointer;
}

/* ---------------------------------------------------
    MAIN DASHBOARD CONTENT
----------------------------------------------------- */

.sd-side-heading {
  font-size: 30px;
  color: var(--headingTextColor);
  padding: 5px 0px 30px;
  font-weight: 400;
}

.sd-side-heading span {
  font-weight: 600;
}

.sd-upcoming-bookings {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 16px 20px 16px 16px;
  display: inline-block;
  width: 100%;
}

.sd-dashboard {
  border-left: 1px solid #cbcbcb;
  padding-left: 30px !important;
  /* min-height: 33vw !important; */
}
.sd-acc {
  color: #2f3193 !important;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 0px !important;
  padding: 10px 10px !important;
}
.sd-side-heading-2 {
  font-size: 17px;
  padding: 10px 0px 20px;
  color: #181818;
  border-bottom: 1px solid #cbcbcb;
}
.sd-side-image {
  width: 100%;
  margin-bottom: 20px;
  margin-top: -10px;
  border-radius: 4px;
}
.sd-upcoming-bookings-table {
  padding-left: 50px;
}

.sd-upcoming-bookings table tr {
  border-bottom: 1px solid #cbcbcb;
  text-transform: capitalize;
}

.sd-upcoming-bookings table tr td {
  padding: 12px 0px;
  text-transform: capitalize;
}

.sd-booking-date,
.sd-booking-time {
  font-size: 13px;
  font-weight: 400;
  color: #6c6c6c;
}
.flex-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.border-c {
  border: 1px solid #bcbcbc;
}
.flexy {
  margin-top: 70px;
  border: 1px solid #bcbcbc;
  padding-top: 20px;
  padding-bottom: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sd-upcoming-bookings table {
  width: 100%;
}

.sd-booking-name a {
  color: var(--headingTextColor);
  font-size: 15px;
  padding: 30px 0px;
  font-weight: 500;
}

.mt--4 {
  margin-top: -4px;
}

.sd-btn-orange {
  background: var(--headingTextColor);
  padding: 4px 10px 5px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff !important;
  border-radius: 4px;
  cursor: pointer;
}

.sd-btn-orange-diff {
  background: #ffffff;
  padding: 10px 19px;
  font-size: 14px;
  border: 1px solid var(--headingTextColor);
  font-weight: 600;
  color: var(--headingTextColor) !important;
  border-radius: 4px;
  cursor: pointer;
}

.sd-btn-orange ::before,
.sd-btn-orange ::after {
  color: white !important;
}

.sd-btn-blue-dark {
  background: #148488;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 10px 5px;
  font-size: 14px;
  font-weight: 600;
}

.sd-btn-green {
  background: #316708;
  color: #fff;
  border-radius: 4px;
  padding: 4px 10px 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.sd-arrow-btn {
  width: 6px;
  margin-top: -2px;
}

.sd-form a.sd-btn-orange .sd-arrow-btn {
  width: auto;
  margin-top: -2px;
  height: 15px;
}

.sd-four-sections {
  border-radius: 4px;
  padding: 16px 20px 26px 16px;
  display: inline-block;
}

.sd-seva-banner {
  background: url("../../../Assets/sd-seva-banner.jpg") no-repeat;
  background-size: cover;
}

.sd-accommodation-banner {
  background: url("../../../Assets/sd-accommodation-banner.jpg") no-repeat;
  background-size: cover;
}

.sd-donation-banner {
  background: url("../../../Assets/sd-donation-banner.jpg") no-repeat;
  background-size: cover;
}

.sd-prabha-banner {
  background: url("../../../Assets/sd-prabha-banner.jpg") no-repeat;
  background-size: cover;
}

.sd-seva-right-para {
  padding-left: 50px;
}

.sd-btn-blue {
  background: #2f3193;
  color: #fff;
  border-radius: 4px;
  padding: 4px 10px 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.sd-border-no {
  border: none !important;
}

.sd-para-text {
  line-height: 18px;
  color: #181818;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  min-height: 54px;
}

/* ---------------------------------------------------
    MAIN DASHBOARD RIGHT SIDE
----------------------------------------------------- */

.sd-dashboard-right-panel .sd-side-heading {
  font-size: 21px;
  padding: 0px 0px 15px;
  border-bottom: 1px solid #cbcbcb;
}

.sd-news-para {
  padding-left: 28px;
}

.sd-news-para p {
  color: #181818;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 3px;
}

.sd-news-para a {
  color: var(--headingTextColor);
  font-weight: 500;
}
.slick-dots {
  bottom: -22px !important;
}

.sd-news-date {
  color: #808080;
  font-size: 12px;
  font-weight: 500;
}

.sd-dashboard-icons {
  position: absolute;
  height: 40px !important;
  width: 40px;
}

.sd-upcoming-events {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 10px 20px 25px;
}
.slick-dots {
  bottom: -15px;
}
/* .slick-next {
  display: none !important;
}
.slick-prev {
  display: none !important;
} */
.slick-dots li {
  margin: 0 1px;
  color: var(--headingTextColor);

  /* bottom: -20px; */
}
.slick-dots li button {
  opacity: 1;
  cursor: pointer;
}
.slick-dots li button::before {
  color: var(--headingTextColor);

  font-size: 12px !important;
  opacity: 1;
}
.slick-active {
  z-index: 10;
}
.slick-dots li.slick-active button:before {
  color: #2f3193 !important;
  font-size: 12px;
}

.slick-active button {
  white-space: nowrap;
  cursor: pointer;
}

.sd-news-updates {
  background: #ededed;
  border-radius: 4px;
  padding: 15px 20px;
  margin-top: 30px;
}

.sd-news-list .item img {
  width: 15px !important;
  height: 19px !important;
}
.sd-border-none {
  border: none;
  outline: none;
  box-shadow: none;
}

/* ---------------------------------------------------
    FAQ
----------------------------------------------------- */

.sd-faqs-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.sd-view-button {
  margin-top: 20px;
  border: none;
  background-color: #f68121;
  color: white;
  font-size: 14px;
  padding: 6px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  align-self: center;
}
.sd-bb-border {
  padding-bottom: 10px;
  border-bottom: 1px solid #cbcbcb !important;
}
.sd-border-bottom-faq {
  border-bottom: 1px solid #cbcbcb !important;
}
.sd-view-button img {
  height: 11px;
  margin-left: 8px;
}
.sd-faq-main .panel {
  background-color: #fff;
  border: none;
  padding: 20px 0px;
  border-top: 1px solid #cbcbcb;
  box-shadow: none;
}

.sd-faq-main .panel-group .panel + .panel {
  margin-top: 0px;
}

.sd-faq-main .panel p {
  color: var(--headingTextColor);
  font-size: 21px;
  width: 28px;
  height: 28px;
  border: 2px solid var(--headingTextColor);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  position: absolute;
  margin-top: 6px;
  margin-bottom: 0px;
}

.sd-faq-main .panel-default > .panel-heading a {
  font-size: 18px;
  font-weight: 600;
  color: var(--headingTextColor);
}

.sd-email {
  position: relative;
}
.sd-email-verified {
  color: #181818;
  font-size: 17px;
  font-weight: 400;
  position: absolute;
  margin: 41.5px 20px;
  display: flex;
  right: 0;
  top: 0;
}
.sd-email-verified-s {
  color: #181818;
  font-size: 17px;
  font-weight: 400;
  position: absolute;
  margin: 40px 20px;
  display: flex;
  right: 0;
  top: 0;
}
.sd-email-verified img {
  height: 20px;
}
.sd-email-verified-s button {
  color: white;
  background-color: var(--headingTextColor);
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 3px;
  border: none;
}
.sd-voilet {
  background-color: white !important;
  border: 1px solid #2f3193 !important;
  border-radius: 4px;
}
.sd-faq-main .panel-default > .panel-heading {
  margin-left: 33px;
  background: none;
  border: none;
}

.sd-form .form-group select,
.sd-seva-form .form-group select {
  background: #eaeaea;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #181818;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../Assets/sd-arrow-dropdown.png") !important;
  background-position-x: right !important;
  background-repeat: no-repeat !important;
  right: 10px;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  height: 44px;
  /* line-height: 22px; */
}
/* .sd-seva-form .form-group select::placeholder {
  text-transform: none;
} */

.sd-faq-main .panel-title > a:after {
  float: right !important;
  font-family: FontAwesome;
  content: "";
  /* background: url("../../../Assets/images/sd-minus.png"); */
  opacity: 1;
  background-position: center center;
  background-size: cover;
  width: 25px;
  height: 20px;
  padding-right: 5px;
}

.sd-faq-main .panel-title > a.collapsed:after {
  float: right !important;
  content: "";
  /* background: url("../../../Assets/images/sd-plus.png"); */
  opacity: 1;
  background-position: center center;
  background-size: cover;
  width: 25px;
  height: 20px;
}

.sd-faq-main .sd-side-heading {
  position: relative;
}

.sd-faq-main .sd-side-heading:after {
  content: "";
  /* background: url("../../../Assets/sd-ganapathihomam-pngs/SD-divider.svg") */
  /* no-repeat; */
  background-position: center center;
  opacity: 0.5;
  width: 162px;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  right: 0px;
}

.sd-faq-main .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #fff !important;
  font-size: 18px;
  color: #181818;
  margin-left: 33px;
  padding-top: 0px !important;
  padding-bottom: 0px;
}

.sd-faq-main .panel-title > a:active,
.sd-faq-main .panel-title > a:focus,
.sd-faq-main .panel-title > a:hover {
  text-decoration: none;
}
.sd-success {
  border: 1px solid #cbcbcb !important;
}
.sd-success img {
  height: 40px;
}
.sd-success-top p {
  margin-left: 10px;
  font-size: 18px;
  margin-bottom: 0px;
  white-space: nowrap;
}

.sd-success-top {
  display: flex;
  padding: 20px;
  white-space: nowrap;
}
.sd-success-bottom {
  padding: 20px;
}
.sd-success-bottom h3 {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 23px;

  /* white-space: nowrap; */
}
.sd-success-bottom h3 p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 0 5px;
  color: var(--headingTextColor);
  white-space: nowrap;
}

.sd-success-bottom .sd-success-bottom-down {
  display: flex;

  margin-top: 15px;
}
.sd-success-bottom .sd-success-bottom-down p {
  color: var(--headingTextColor);
  font-weight: 600;
  border-left: 10px;
  margin-bottom: 0px;
  padding-left: 10px;
  cursor: pointer;
  white-space: nowrap;
}
.upload-button {
  display: flex;
  justify-content: space-between;
  padding: 9px 20px 9px 20px;
  border: 1px solid #2f3193;
  border-radius: 4px;
  height: 44px;
  cursor: pointer;
  text-overflow: ellipsis;

  align-items: center;
}
.upload-button img {
  width: 18px;
}
.upload-button {
  font-size: 16px;
  cursor: pointer;
}
.upload-button p {
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 400;
  color: #181818;
}
.opa {
  opacity: 0.5;
}

.sd-border-right {
  border-right: 1px solid #eaeaea;
  padding-left: 0px !important;
  padding-right: 10px;
}
.sd-green {
  background-color: #d4edda;
}
.sd-green p {
  color: #185a26;
  margin-bottom: 0px;
}

.sd-redback {
  background-color: #f7d7da;
}
.sd-redback p {
  color: #721d24;
  margin-bottom: 0px;
  word-wrap: wrap;
  white-space: pre-wrap;
}
.form-control option {
  padding-right: 15px;
  padding: 5px;
}

/*----------------------------------------------------
devotee ma list
------------------------------------------------------*/
.pr-5 {
  padding-right: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.sd-btn-orange-lg {
  background: var(--headingTextColor);
  padding: 8px 10px 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  color: #ffffff !important;
  font-weight: 400;
  margin-right: 30px;
  cursor: pointer;
}

.sd-btn-orange-lg .sd-arrow-btn {
  width: 16px;
  margin-top: -2px;
  margin-right: 8px;
}

.sd-favorites-list {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 30px;
}
.h-20 {
  height: 23px !important;
  margin-right: -6px;
}
.sd-master-list-name {
  font-size: 18px;
  color: var(--headingTextColor);
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  text-transform: capitalize;
}
.sd-favorites-list p {
  margin-bottom: 5px;
}

.sd-adaar-card-bg {
  background: #e7e8fe;
  padding: 20px 20px;
}

.sd-passwords {
  position: relative;
}
.sd-passwords img {
  height: 17px;
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}
.sd-passwords img:hover + div {
  display: block;
}

.sd-passwords input {
  padding-right: 30px;
  text-transform: capitalize;
}
.sd-passwords div {
  display: none;
  position: absolute;
  top: -100px;
  z-index: 3;
  width: 300px;
  padding: 10px;
  right: -10px;
  background: #555555;
  border: 1px solid #555555;
  border-bottom: 9px solid #555555;
  color: white;
  border-radius: 4px;
  clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 92%,
    87% 93%,
    90% 100%,
    93% 93%,
    100% 93%
  );
}

.sd-passwords-tt {
  position: relative;
}
.sd-passwords-tt img {
  height: 17px;
  position: absolute;
  top: 2px;
  right: -25px;
  cursor: pointer;
  z-index: 2;
}
.sd-passwords-tt img:hover + div {
  display: block;
}

.sd-passwords-tt input {
  padding-right: 30px;
}
.sd-passwords-tt div {
  display: none;
  position: absolute;
  top: -120px;
  z-index: 3;
  width: 300px;
  padding: 10px;
  right: -270px;
  background: #555555;
  border: 1px solid #555555;
  border-bottom: 9px solid #555555;
  color: white;
  border-radius: 4px;
  clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 90%,
    11% 90%,
    16% 99%,
    21% 90%,
    75% 91%,
    100% 90%
  );
}
.sd-publications {
  display: flex;
  flex-wrap: wrap;
}
.sd-dd-boxes {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 30px; */
  flex-wrap: wrap;
}
.sd-hide {
  display: none;
}
.sd-dd-box-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.sd-dd-box-one img {
  height: 35px;
  cursor: pointer;
}
.sd-copy-c {
  position: absolute;
  top: 20px;
  font-size: 18px;
  right: 47%;
  font-weight: 500;
  background-color: rgb(228, 252, 253);
  padding: 5px 15px;
  border-radius: 4px;
  animation: slow-message 0.5s;
  border: 1.5px solid #bfbfbf;
}
.fs-16 {
  font-size: 16px;
}
.sd-dd-box-one .sd-dd-hidden {
  /* display: none; */
  border: 1px solid #bcbcbc;
  background-color: white;
  min-width: 120px;
  right: 0;
  top: 35px;
  border-radius: 4px;

  position: absolute;
}
.sd-dd-box-one .sd-dd-hidden div {
  padding: 5px 10px;
  text-align: center;
}
.m-400 {
  min-width: 400px;
}
.cc-1 {
  border-width: 2px;
  border-color: rgb(203, 230, 206);
  border-style: solid;
  border-radius: 10px;
  background-color: rgb(237, 255, 239);
}
.color-1 {
  color: #529c58;
}
.color-2 {
  color: #605599;
}
.color-3 {
  color: #9a705b;
}
.color-4 {
  color: #397e91;
}
.cc-2 {
  border-width: 2px;
  border-color: rgb(222, 218, 239);
  border-style: solid;
  border-radius: 10px;
  background-color: rgb(244, 242, 255);
}
.cc-3 {
  border-width: 2px;
  border-color: rgb(233, 214, 205);
  border-style: solid;
  border-radius: 10px;
  background-color: rgb(255, 242, 236);
}
.cc-4 {
  border-width: 2px;

  border-color: rgb(202, 225, 231);
  border-style: solid;
  border-radius: 10px;
  background-color: rgb(236, 251, 255);
}
.flex-start {
  justify-content: flex-start !important;
}
.sd-dd-box {
  width: 23%;
  border-radius: 4px;
  padding: 17px;
  justify-content: space-around;
  min-width: 200px;
  margin-bottom: 30px;
  margin-right: 25px;
}
.rotate {
  transform: rotate(180deg);
  height: 12px;
  margin-right: 3px;
  cursor: pointer;
}
.coupon-box {
  cursor: pointer;
  width: 300px;
  min-width: 300px;
  height: 80px;
  display: flex;
  background-color: #fff;
  /* border: 1px solid #f68121; */
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}
.sd-acc-blocks {
  display: flex;
  padding: 5px;
  border: 1px solid #f88427;
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
}
.sd-block-name {
  font-size: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding: 5px;
}
.sd-room-name {
  background-color: #eec29f;
  border-radius: 6px;
  padding: 5px;
  font-size: large;
  text-align: center;
}
.sd-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: var(--headingTextColor);
}
.sd-plain-border {
  border: 1px solid #eec29f;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
}
.sd-selected-border {
  border: 1px solid #eec29f;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: var(--headingTextColor);
}
.coupon-box .cc-left {
  background-color: #eec29f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90px;
  font-size: large;
  text-align: center;
}
.coupon-box .cc-right {
  background-color: #ffe8cc;
  height: 100%;
  width: 220px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 35px;
}
.coupon-box .cc-used {
  position: absolute;
  right: 0px;
  /* background-color: rgba(255, 0, 0, 0.637); */
  border-bottom-left-radius: 10px;
  padding: 3px 8px 3px 8px;

  color: white;
}
.opacity-05 {
  opacity: 0.5;
}
.coupon-box .used-c {
  background-color: var(--headingTextColor);
}

.coupon-box .not-used-c {
  background-color: #f55220;
}
.coupon-box .cc-right p {
  font-size: 17px;
  margin-bottom: 0px;
  color: grey;
}
.coupon-box .cc-right .cc-right-heading {
  font-size: 16px;
  color: grey;
}
.sd-dd-box .sd-coupon-code {
  font-size: 18px !important;
  padding: 5px;
  border: 1px solid #cbcbcb !important;
  border-radius: 3px;
}
/* .sd-dd-box .sd-coupon-code-p {
  padding: 10px 0px 10px 0px;
} */
.up-circle {
  width: 20px;
  position: absolute;
  bottom: 0px;
  margin-left: 75px;
  height: 10px; /* half the width*/
  border-radius: 100px 100px 0 0; /* upper and right are the length of height*/
  background-color: #fff;
  /* border: 1px solid gray; */
  border-bottom: none;
}
.down-circle {
  position: absolute;
  width: 20px;
  margin-left: 75px;
  height: 10px; /* half the width*/
  border-radius: 0 0 100px 100px; /* upper and right are the length of height*/
  background-color: #fff;
  /* border: 1px solid gray; */
  border-bottom: none;
}
.sd-cc-h3 {
  font-size: 17px !important;
  padding-top: 10px !important;
}
.sd-dd-box:last-child {
  margin-right: 0 !important;
}
.sd-dd-spcl {
  height: 20px !important;
}
.p-box {
  display: flex;
  padding: 17px 17px 14px 17px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  min-width: 400px;
  margin-bottom: 20px;
  min-height: 230px;
}
.sd-dd-box p {
  margin-bottom: 0;
}

.sd-dd-box h3 {
  padding: 20px 0px 10px;
  font-size: 19px;
  line-height: 24px;
}
.sd-dd-box h2 {
  font-size: 36px;
}
.w-90 {
  width: 90% !important;
}
.min-350 {
  min-width: 400px !important;
}
.p-box .sd-p-one-left {
  /* height: 180px; */
  /* width: 150px; */
  border-radius: 4px;
  min-height: 180px;
  min-width: 150px;
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
.p-box .sd-p-one-left img {
  width: 150px;
  height: 100%;
}
.p-box .sd-p-one-right {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.p-box .sd-p-one-right h3 {
  color: var(--headingTextColor);
  font-size: 20px;
  padding: 8px 0px;
}
.p-box .sd-p-one-right button {
  padding: 3px 12px;
  margin-bottom: 15px;
  background: #fde1c8;
  font-size: 14px;
  font-weight: 600;
  color: #181818;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: none;
  align-items: center;
}
.p-box .sd-p-one-right p {
  color: #181818;
  font-weight: 500;
}
.sd-btn-light-pink {
  padding: 10px 12px;
  background: #fde1c8;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: #181818;
  text-align: center;
  display: block;
  border-radius: 4px;
}
.sd-btn-light-pink img {
  margin-right: 5px;
}
.sd-btn-orange-lg {
  background: var(--headingTextColor);
  padding: 8px 10px 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  color: #ffffff !important;
  font-weight: 400;
  margin-right: 30px;
}
.sd-p-one-right .sd-p-one-right-inner p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 24px;
}
.sd-clamp {
  overflow: hidden;
  align-self: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 24px;
  margin-bottom: 0;
  color: #181818;
}

.sd-btn-orange-lg .sd-arrow-btn {
  width: 16px;
  margin-top: -2px;
  margin-right: 8px;
}
.mlr-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.mbb-0 {
  margin-bottom: 0px !important;
}
.w-85 {
  top: 88px;
  max-height: 225px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: scroll;
  background-color: white;
}
.w-85 div {
  border-bottom: 1px solid #bdbdbd;
}
.sd-favorites-list {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 30px;
}

.sd-master-list-name {
  font-size: 18px;
  color: var(--headingTextColor);
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.sd-adaar-card-bg {
  background: #e7e8fe;
  padding: 20px 20px;
}

.sd-btn-light-pink {
  padding: 10px 12px;
  background: #fde1c8;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: #181818;
  text-align: center;
  display: block;
  border-radius: 4px;
}
/* ---------------------------------------------------
    FORM
----------------------------------------------------- */

.custom-label {
  display: inline-block;
  background-color: #eaeaea !important;
  /* border: 1px solid #efefef; */
  color: white !important;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

hr {
  border-top: 1px solid #cbcbcb;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sd-form .form-group label {
  color: #181818;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}

.sd-form .form-group {
  margin-bottom: 24px !important;
  position: relative;
}
.sd-form .form-group .sd-EM {
  position: absolute;
  bottom: -21px;
  right: 0px;
  text-align: right;
  color: red;
}
.sd-form .form-group .sd-error-m {
  position: absolute;
  bottom: -35px;
  right: 0px;
  text-align: right;
  color: red;
}
.opace {
  opacity: 0.5;
}

.sd-form .sd-EM {
  bottom: -35px;
  right: 0px;
  text-align: right;
  color: red;
}

option {
  cursor: pointer;
}
.sd-form .form-group input {
  background: #eaeaea;
  height: 44px;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #181818;
  font-size: 17px;
  text-transform: capitalize;

  outline: none;
  font-weight: 400;
}
.sd-form .form-group select {
  font-size: 17px;
}
.form-control option {
  font-weight: 400;
  padding: 10px;
}
.sd-form .form-group select {
  padding-left: 10px;
  padding-right: 40px;
}
.sd-form .form-group select:hover {
  background-color: white;
  border: 1px solid #2f3193;
}
.sd-form .form-group input:hover {
  background-color: white;
  border: 1px solid #2f3193;
}
.sd-form .form-group input:focus {
  background-color: white;
  border: 1px solid #2f3193;
}

.sd-form .form-group select option {
  cursor: pointer;
  font-weight: 400;
  padding-left: 5px;
}
.sd-form .form-group textarea {
  background: #eaeaea;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  line-height: 24px;
  color: #181818;
}
.sd-text-blue {
  color: var(--headingTextColor);
  font-weight: 600;
  cursor: pointer;
}

.sd-para-text-18 {
  line-height: 18px;
  color: #181818;
  font-size: 18px;
}

.sd-form-button-orange {
  margin-top: 40px;
}

.sd-form a.sd-btn-orange {
  font-size: 18px;
  padding: 10px 19px;
}

.sd-profile input,
.sd-profile select {
  background: #fff;
  border: 1px solid #b5b5b5;
  font-size: 17px;

  font-weight: 500;
  color: #181818;
}
.sd-profile input {
  text-transform: capitalize;
}
.sd-profile select {
  font-weight: 400 !important;
}
.sd-profile hr {
  margin-top: 8px;
  clear: both;
}

.sd-profile .form-group {
  padding-left: 15px;
  padding-right: 15px;
}

.sd-profile-mobile input {
  padding-left: 100px;
  font-size: 17px;
  text-transform: capitalize;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100% !important;
  font-size: 16px;
  height: 44px;
  border-radius: 4px;
}
.sd-txt {
  text-transform: none;
}
.customDatePickerWidth:focus-within {
  border: 1px solid #2f3193;
  background-color: white !important;
  border-radius: 4px;
}
.customDatePickerWidth:focus-within
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper {
  background-color: white !important;
}
.customDatePickerWidth:focus
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  background-color: white !important;
  border: none !important;
}

.sd-cyan {
  background-color: #c3c4fb !important;
}
.sd-more-letter {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dee2fb;
}

.customDatePickerWidth:hover {
  border: 1px solid #2f3193;
  background-color: white !important;
  background: white !important;
}
.customDatePickerWidth:hover > .react-date-picker,
.customDatePickerWidth:hover > .react-date-picker.react-date-picker__wrapper,
.customDatePickerWidth:hover
  > .react-date-picker.react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-date-picker__inputGroup__input {
  border: none;
  background: white !important;
  background-color: white !important;
}
.customDatePickerWidth:hover
  > .react-date-picker
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-date-picker__inputGroup__input {
  border: none;
  background: white !important;
  background-color: white !important;
}
.customDatePickerWidth .react-date-picker .react-date-picker__wrapper {
  border-radius: 4px;
}
.customDatePickerWidth:hover
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container {
  background-color: white;
}
.customDatePickerWidth:focus-within
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  border: none !important;
  height: 98%;
  background-color: white;
}
.customDatePickerWidth:hover
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper {
  background-color: white;
}
.customDatePickerWidth:hover
  > .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  background-color: white;
  height: 98%;
  border: none;
}

.sd-date-voilet {
  border-radius: 4px;
  border: 1px solid #2f3193;
  background-color: white !important;
  background: white !important;
}
.sd-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-date-picker__inputGroup__input {
  background-color: white !important;
  background: white !important;
  border: none !important;
  height: 40px;
}
.sd-date-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  background-color: white;
  background: white !important;
  height: 100%;
  text-transform: capitalize;
  border-radius: 4px;
}
.sd-date-voilet
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  background: white !important;
}

.sd-date-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  input:hover {
  border: none !important;
}

.sd-date-voilet
  .react-date-picker
  .react-datepicker-wrapper
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container {
  background-color: white;
  background: white;

  height: 100%;
}
.sd-unq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 15px;
}
button {
  outline: none !important;
}
.fe {
  justify-content: flex-end !important;
}
.sd-date-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup_input
  .react-datepicker__input-container:hover {
  border: none !important;
}
.sd-date-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup_input
  .react-datepicker__input-container
  input {
  background: white !important;
}
.sd-date-voilet .react-date-picker {
  background-color: white;
  background: white;

  border-radius: 4px;
  height: 100%;
}
.sd-date-voilet .react-date-picker {
  width: 98%;
}
.react-date-picker {
  border-radius: 4px;
  width: 100% !important;
}
.sd-dob {
  position: relative;
  /* margin: 1px; */
}
.react-datepicker-wrapper input {
  border-radius: 4px;
  height: 42px !important;
  padding: 6px 12px;
}

.react-datepicker-wrapper input:hover {
  border: none !important;
}

.react-datepicker-wrapper input:focus {
  border: none !important;
}
.react-datepicker-wrapper input:active {
  border: none !important;
}

.DayPickerInput {
  width: 100%;
}
.DayPicker {
  font-size: 14px;
}
.DayPicker-Day {
  font-size: 1.5rem;
}
.DayPicker-Month {
  font-size: 1.5rem;
}
.DayPickerInput input {
  width: 100%;
  padding-left: 15px;
}

.sd-dob img {
  position: absolute;
  right: 15px;
  top: 13px;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
}

.sd-profile-mobile .prefix img {
  margin-right: 10px;
  height: 20px;
}
.sd-profile-mobile .suffix img {
  margin-right: 10px;
  height: 20px;
}

.sd-profile-mobile .prefix {
  color: #181818;
  font-size: 17px;
  font-weight: 400;
  position: absolute;
  margin: 11.5px 20px;
  display: flex;
}
.sd-profile-mobile .suffix {
  color: #181818;
  font-size: 17px;
  font-weight: 400;
  position: absolute;
  margin: 41.5px 20px;
  display: flex;
  right: 0;
  top: 0;
}
.sd-profile-mobile .prefix:hover {
  background-color: white;
}
.sd-profile-mobile .prefix:hover + input {
  background-color: white;
  border: 1px solid #2f3193;
}

.sd-form-button-update {
  padding-top: 20px;
  cursor: pointer;
}

.sd-form-button-update a {
  padding: 7px 10px;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0px !important;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  bottom: -6px;
  list-style: none;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  padding: 0px !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 7px;
  height: 7px;
  margin: 5px 6px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--headingTextColor) !important;
}

.fa-search {
  color: #24292d;
}

/* ---------------------------------------------------
    PAROKSH SEV
----------------------------------------------------- */

.sd-seva-content .col-md-4,
.sd-seva-content .col-lg-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.sd-seva-content .sd-seva-box {
  border: 1px solid #c9c9c9;
  padding: 12px 12px 18px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.sd-seva-content .sd-seva-box .sd-acc-img {
  height: 100%;
  max-height: 180px;
  min-height: 180px;
  /* width: 100%; */
  border-radius: 2px;
}
.sd-seva-box h4 {
  color: var(--headingTextColor);
  padding: 0px 0px 10px 0px !important;
  border: none;
  float: none;
  display: inline-block;
  height: 59px;
  vertical-align: middle;
  text-align: center;
  text-transform: capitalize;
}
.sd-seva-box h4 div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sd-seva-box .sd-seva-time {
  font-size: 16px;
  color: #454545;
  background: #e7e8fe;
  padding: 6px;
  border-radius: 4px;
  font-weight: 500;
}
.sd-dum {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}
.sd-seva-box .sd-seva-time img {
  margin-top: -3px;
  padding-right: 10px;
}

.sd-seva-box .sd-seva-cost {
  font-size: 18px;
  color: #181818;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.sd-seva-box .sd-seva-cost img {
  height: 14px;
  margin-right: 3px;
}
.sd-darshanam-content {
  display: flex;
  flex-wrap: wrap;
}
.sd-darshanam-content .col-md-4,
.sd-darshanam-content .col-lg-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.sd-darshanam-content .sd-darshanam-box {
  border: 1px solid #c9c9c9;
  padding: 10px 10px 12px;
  border-radius: 4px;
  margin: 15px 20px 20px 0px;
}
.sd-darshanam-content .sd-darshanam-box .sd-acc-img {
  height: 100%;
  max-height: 180px;
  min-height: 180px;
  /* width: 100%; */
  border-radius: 2px;
}
.sd-darshanam-box h4 {
  color: var(--headingTextColor);
  padding: 0px 0px 10px 0px !important;
  border: none;
  float: none;
  display: inline-block;
  height: 59px;
  vertical-align: middle;
  text-align: center;
  text-transform: capitalize;
}
.sd-darshanam-box .sd-darshanam-radio [type="radio"],
input[type="radio"] {
  height: 20px;
  width: 20px;
}

.sd-darshanam-box h4 div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sd-darshanam-box .sd-darshanam-time {
  font-size: 16px;
  color: #454545;
  background: #e7e8fe;
  padding: 6px;
  border-radius: 4px;
  font-weight: 500;
}

.sd-darshanam-box .sd-darshanam-time img {
  margin-top: -3px;
  padding-right: 10px;
}

.sd-darshanam-box .sd-darshanam-cost {
  font-size: 18px;
  color: #181818;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.sd-darshanam-box .sd-darshanam-cost img {
  height: 14px;
  margin-right: 3px;
}

.sd-side-heading-h4 {
  font-size: 20px;
  color: var(--headingTextColor);
  padding: 5px 0px 30px;
  text-transform: capitalize;
}

.sd-side-heading-h3 {
  font-size: 24px;
  color: var(--headingTextColor);
  padding: 5px 0px 30px;
  line-height: 30px;
  text-transform: capitalize;
}
.sd-side-heading-h3 span {
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
}
.pr-0 {
  padding-right: 0px !important;
}
.sd-terms-list {
  margin-left: 0px;
}
.TC {
  text-transform: capitalize;
}
.sd-terms-list li {
  font-size: 15px;
  color: #555555 !important;
  font-weight: 400;
  margin-bottom: 15px;
  display: list-item;
  list-style: decimal;
  margin-left: 0px;
}
.sd-terms-list li a {
  color: #555555 !important;
}
.react-date-picker__calendar .react-calendar {
  width: 330px !important;
}
.mr-34 {
  margin-right: 34px !important;
}
.sd-terms-checkbox {
  margin-bottom: 0px !important;
  display: "flex";
}

.sd-terms-checkbox input {
  background: #eaeaea;
  height: 25px !important;
  width: 25px;
  line-height: 31px;
  /* margin-top: 10px; */
  position: relative;
  top: 0px;
  margin-top: 0px;
  text-transform: capitalize;
  margin-left: -15px;
  margin-right: 10px;
  /* right: 16px; */
  background-color: #eaeaea;
  border: none;
}

.sd-seva-form hr {
  margin-top: 8px;
  clear: both;
  margin-bottom: 25px;
}

.sd-seva-form .form-group input {
  background: #eaeaea;
  line-height: 44px;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  text-transform: capitalize;

  -webkit-box-shadow: none;
  border: none;
}
.mr-150 {
  margin-right: 110px;
}
.b-20 {
  bottom: -23px !important;
}
.sd-terms-checkbox {
  font-size: 17px;
  color: #181818;
  display: flex;
  align-items: center;
}

.sd-terms-checkbox a {
  color: var(--headingTextColor);
  font-weight: 500;
}

.sd-confirm-edit-button img {
  margin-right: 10px;
}
.sd-accept {
  font-size: 16px;
  /* left: 25px; */
  left: -15px;
  color: red;
  position: absolute;
  top: 23px;
  /* margin-bottom: 0px; */
}
.react-datepicker__day--selected {
  background-color: #2f3193 !important;
  color: white !important;
}
.react-datepicker__day--outside-month {
  background-color: transparent !important;
}
.react-datepicker__day--outside-month:active
  > .react-datepicker__day--selected {
  background-color: transparent !important;
}

.sd-confirm-edit-button {
  border: 1px solid #c3c4fb;
  padding: 7px 16px;
  cursor: pointer;

  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  color: var(--headingTextColor);
  float: right;
  cursor: pointer;
}
.sd-confirm-edit-autofill {
  border: 1px solid #c3c4fb;
  padding: 7px 16px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  color: var(--headingTextColor);
  float: right;
  cursor: pointer;
  position: relative;
}
.mr-3 {
  margin-right: 3px;
  margin-left: 3px;
  text-transform: capitalize;
}
.sd-autofill {
  position: absolute;
  border: 1px solid #c9c9c9;
  background-color: white;
  border-radius: 6px;
  z-index: 5;
  padding: 2px;
  max-height: 260px;
  margin-top: 6px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  animation: up 0.1s;
}
.bg {
  background-color: white !important;
}
.br-4 {
  border-radius: 4px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.mb-0 {
  margin-bottom: 10px !important;
}
.min-400 {
  min-width: 400px !important;
}
.sd-r {
  right: 0px;
}
.sd-b {
  left: 0px;
  /* top: -13px;
  left: 80px; */
}
.sd-mul {
  left: 0;
  display: flex;
}
.sd-autofill::-webkit-scrollbar {
  display: none;
}
.sd-autofill-div {
  display: flex;
  justify-content: space-between;
  background-color: #c3c4fb;
  padding: 7px;
  margin: 10px;
  border-radius: 4px;
  min-width: 300px;
}

.sd-autofill-div p {
  white-space: nowrap;
  padding: 10px;
  color: #181818;
  font-weight: 500;
  margin-bottom: 0px;
  cursor: pointer;
  text-transform: capitalize;
}
.sd-paroksha-seva.active {
  background: #2f3193;
}

.sd-paroksha-seva img {
  margin-top: -6px;
}
@keyframes up {
  0% {
    margin-top: 15px;
  }
  100% {
    margin-top: 6px;
  }
}
@keyframes up-extra {
  0% {
    margin-top: 50px;
  }
  100% {
    margin-top: 44px;
  }
}
.sd-paroksha-seva {
  background: #c3c4fb;
  padding: 0px 10px;
  margin-left: -6px;
}

.sd-seva-box .sd-btn-orange {
  font-size: 16px;
  padding: 8px 12px;
  font-weight: 500;
}

.sd-devotee-details,
.sd-booking-details {
  border: 1px solid #cbcbcb;
}
.sd-booking-details h4 {
  margin: 0px !important;
}

.sd-devotee-details h4,
.sd-devotee-details tr th {
  text-transform: uppercase;
  font-size: 15px;
  color: #666666;
  padding: 14px 12px;
  font-weight: 600;
}

.sd-devotee-details tr th {
  background: #e6e7fd;
  font-size: 15px;
}

.sd-devotee-details .table {
  margin-bottom: 0px !important;
}
.tt {
  text-transform: capitalize;
}
.col-md-7,
.col-md-7 {
  width: 66.66%;
}

.sd-devotee-details tr td {
  font-size: 16px;
  color: #181818;
  padding: 13px 12px !important;
  border: 1px solid #efefef !important;
  font-weight: 500;
  text-transform: capitalize;
}
.border-v {
  border: 1px solid #2f3193;
  border-top: none;
  z-index: 10;
  bottom: -98px;
  padding-bottom: 0px !important;
  margin-bottom: 14px;
}
.relative {
  position: relative;
}

.sd-booking-details tr th {
  font-size: 16px;
  color: #777777;
  padding: 5px 12px 5px 20px !important;
  font-weight: 600;
  border-left: 1px solid #ddd;
  border-right: none;
  border-top: none !important;
  border-bottom: none !important;
}
select::placeholder {
  text-transform: none !important;
}

.sd-booking-details tr th:first-child,
.sd-booking-details tr td:first-child {
  border-left: none !important;
  padding-left: 5px 12px 5px 14px !important;
}
.flex-a {
  display: flex;
  align-items: center;
}
.mt-26 {
  margin-top: 26px;
}
.sd-booking-details .table-bordered {
  border: none;
  margin-top: 20px;
}

.sd-booking-details tr td {
  font-size: 18px;
  color: #181818;
  padding: 5px 12px 5px 20px !important;
  border-left: 1px solid #ddd;
  border-right: none;
  border-top: none !important;
  border-bottom: none !important;
  font-weight: 400;
}
.sd-table-detoggler {
  display: none;
}
.react-datepicker__day--disabled {
  color: #ccc !important;
}
.react-datepicker__day--selected {
  /* color: #f68121; */
}
.bt-none {
  border-bottom: none !important;
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.react-date-picker__inputGroup__leadingZero {
  font-size: 17px;
  font-weight: 400;
}
.sd-booking-details h4 {
  border: none !important;
  background: #fbebdd;
  border-bottom: 1px solid #cbcbcb !important;
  text-transform: uppercase;
  font-size: 15px;
  color: #666666;
  padding: 14px 12px;
  font-weight: 600;
}
.sd-faq-top-border {
  border-bottom: 1px solid #cbcbcb !important;
}
.size ::before {
  height: 15px !important;
  width: 15px !important;
}
.sd-acc-profile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.selectDiv {
  position: absolute;
  z-index: 1;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
}
.templeSelect {
  padding: 5px 10px;
  cursor: pointer;
}

.templeSelect:hover {
  background-color: #eaeaea;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 991px) {
  .sm-pt-30 {
    padding-top: 30px;
  }
  .xssm-pl-0 {
    padding-left: 0px !important;
  }
  .xssm-pr-0 {
    padding-right: 0px !important;
  }
  .sd-dashboard {
    border-left: none;
    padding-left: 15px !important;
  }
  .col-md-7,
  .col-md-7 {
    width: 100%;
  }
}
@media (min-width: 500px) and (max-width: 1333px) {
  /* .sd-dd-box:last-child {
    margin-right: 30px !important;
  } */
  .sd-dd-box {
    width: 47%;
    margin-right: 0px;
  }
}
/* @media (min-width: 992px) and (max-width: 1199px) {
  .sd-upcoming-bookings table tr td {
    padding: 12px 3px 12px 0px;
  }
  .sd-upcoming-bookings {
    padding: 14px;
  }
  .sd-four-sections {
    width: 100%;
  }
  .sd-side-heading-2 {
    font-size: 17px;
    padding: 16px 0px 30px;
  }
  .sd-four-sections {
    padding: 16px 11px 30px;
  }
  .sd-four-padding {
    padding-right: 0px;
  }
} */
/* @media (min-width: 750px) and (max-width: 1000px) {
  .sd-dd-boxes {
    justify-content: flex-start;
  }
  .sd-dd-box:last-child {
    margin-right: 30px !important;
  }
  .sd-dd-box {
    width: 30.5%;
  }
} */
@media (min-width: 1200px) and (max-width: 1333px) {
  .sd-side-heading-2 {
    font-size: 17px;
    padding: 16px 0px 30px;
  }
  .sd-four-sections {
    padding: 16px 11px 30px;
  }
}
@media (max-width: 1370px) {
  .sd-acc-main {
    width: 100% !important;
  }
  .sd-acc-sub {
    width: 100% !important;
  }
}

@media (min-width: 1700px) {
  .sd-heading-18 {
    padding: 0px 0 0 75px;
  }
}
@media (min-width: 1200px) {
  .flex-j {
    display: flex;
    justify-content: space-between;
  }
  .sd-seva-content .col-md-4,
  .sd-seva-content .col-lg-4 {
    padding-left: 0px;
    padding-right: 30px;
  }
  .sd-side-menu.col-lg-3 {
    width: 20%;
    padding-right: 30px;
    /* border-right: 1px solid #cbcbcb; */
  }
  .sd-dashboard.col-lg-9 {
    width: 80%;
  }
  .sd-dashboard .col-lg-8 {
    width: 72%;
  }
  .sd-dashboard-right-panel.col-lg-4 {
    width: 28%;
  }
  .sd-side-heading-2 {
    padding: 10px 0px 20px;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .min-400 {
    width: 100% !important;
  }
}
@media (max-width: 900px) {
  .pl-0 {
    padding-right: 0px !important;
  }
  .customDatePickerWidth {
    width: 100% !important;
    padding-right: 0px !important;
  }
  .w-90- {
    width: 100% !important;
    padding-right: 15px;
  }
  .pr-0 {
    padding-left: 0 !important;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .xssm-pr-0 {
    padding-left: 0 !important;
  }
  .sd-dashboard {
    padding-left: 0px !important;
    border: none;
    /* padding-left: 0;
    padding-right: 0; */
  }
}
@media (max-width: 769px) {
  .sd-acc-calender .react-datepicker .react-datepicker__month-container {
    width: 100%;
  }
  .sd-acc-profile {
    display: inherit;
    width: 100%;
  }
  .sd-acc-calender .react-datepicker {
    width: 100%;
    min-width: 300px;
  }
  .sd-acc-calender {
    width: 100%;
  }
  .sd-acc-main {
    width: 100% !important;
    min-width: 50%;
  }
  .ml--15 {
    margin-left: 0px;
    padding-left: 5px;
  }

  .xs-pl-0 {
    padding-left: 0px !important;
  }
  .xs-pr-0 {
    padding-right: 0px !important;
  }
  .xs-pt-30 {
    padding-top: 30px;
  }
  .sd-four-sections {
    width: 100%;
  }
  .sd-side-heading-h3 {
    font-size: 22px;
    text-align: center;
  }
  .sd-side-heading-h3 span {
    font-size: 20px;
  }
  .mlr-30 {
    margin-left: 0;
    margin-right: 0px;
    padding-right: 30px;
  }
  .row {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
  }
  .sd-dashboard {
    border: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .sd-devotee-details tr td {
    font-size: 14px;
  }
  .flex-j {
    display: flex;
    flex-direction: column;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 5px !important;
  }
  .sd-adaar-card-bg {
    padding: 10px 0px;
    margin: 4px;
  }

  .flex span {
    width: 50%;
    margin: 4px;
  }
  .sd-650 {
    display: flex;
    flex-direction: column;
  }
  .sd-650 span {
    margin-bottom: 10px;
  }
  .sd-devotee-details tr th {
    font-size: 14px;
  }
  .sd-toggle-table {
    display: none;
  }
  .sd-table-detoggler {
    display: block;
  }

  .sd-booking-details {
    overflow: scroll;
  }
  .sd-b-d-inner {
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
    border: 1px solid #bcbcbc;
  }
  .sd-booking-details-up {
    padding: 10px 15px;
    background: #e6e7fd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sd-booking-details-middle {
    padding: 10px 15px;
  }
  .sd-booking-details-middle div {
    display: flex;
    flex-direction: column;
  }
  .sd-booking-details-middle label {
    color: grey;
    font-weight: 500;
  }
  .sd-booking-details-middle span {
    color: #181818;
    font-weight: 600;
  }
  .sd-booking-details-down {
    display: flex;
    padding: 10px 15px;
    border-top: 1px solid #bcbcbc;

    justify-content: space-between;
  }

  .sd-booking-details-down div {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
  .sd-booking-details-up .sd-actions-icons-main a {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sd-booking-details-down:last-child {
    margin-right: 0px;
  }
  .sd-booking-details-down label {
    color: grey;
    font-weight: 500;
  }
  .sd-booking-details-down span {
    color: #181818;
    font-weight: 600;
  }
  .route {
    border-left: 1px solid #bcbcbc;
  }
  .sd-profile .form-group {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sd-terms-list {
    padding-left: 20px;
  }
  .sd-terms-checkbox {
    margin-left: 20px;
  }
  .sd-devotee-details,
  .sd-booking-details {
    overflow: scroll;
  }
}
@media (max-width: 600px) {
  .react-tel-input .form-control {
    padding: 5px 14px 5px 60px !important;
  }
  .sd-darshanam-box {
    margin: 10px 0px !important;
    width: 100%;
  }
  .sd-darshanam-box .sd-side-heading-2 span {
    white-space: break-spaces !important;
  }
}
@media (max-width: 550px) {
  .ccavenue {
    height: 100%;
  }
  .temple-select .form-control {
    width: 320px I !important;
  }
  .min-400 {
    min-width: 200px !important;
  }
  .sd-dd-box {
    width: 99%;
    margin-right: 0px;
  }
  /* .sd-accept {
    font-size: 16px;
    left: 67px;
    color: red;
    position: absolute;
    margin-top: 55px;
  } */
  .sd-autofill {
    max-width: 380px;
  }
  .sd-autofill-div p {
    font-size: 13px;
    word-wrap: normal;
  }
  .sd-terms-checkbox input {
    top: 0px;
    /* margin-top: -10px; */
  }
  .sd-terms-checkbox {
    display: flex;
    margin-left: 20px;

    /* justify-content: center; */
  }
}
@media (max-width: 484px) {
  .sd-profile .form-group {
    padding-left: 0px;
    padding-right: 0px;
  }

  .react-date-picker__calendar .react-calendar {
    width: 280px !important;
  }
  .sd-terms-checkbox {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .sd-terms-checkbox input {
    margin-top: 0px;
    top: 0px;
  }
  .p-15 {
    margin-right: 15px;
  }
  .sd-passwords-tt div {
    width: 250px;
    right: -150px;
    top: -140px;
    clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 91%,
      42% 91%,
      46% 100%,
      50% 91%,
      75% 91%,
      100% 91%
    );
  }
  .sd-devotee-details,
  .sd-booking-details {
    border: 1px solid #cbcbcb;
    margin-top: 43px;
    overflow: scroll;
  }
  .sd-confirm-edit-button {
    margin-top: 19px;
    width: 100%;
    clear: both;
  }
  .sd-confirm-edit-autofill {
    margin-top: 19px;
    width: 100%;
    clear: both;
  }
  .sd-side-heading-h3 {
    font-size: 18px;
    text-align: center;
  }
  .sd-side-heading-h3 span {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .temple-select .form-control {
    width: 310px !important;
  }
  .p-box {
    min-width: 200px !important;
    flex-direction: column;
    align-items: center;
  }
  .p-box .sd-p-one-left {
    height: inherit;
  }
  .p-box .sd-p-one-right {
    align-items: center;
    padding-left: 0;
    text-align: center;
  }
  .p-box .sd-p-one-right-inner {
    align-items: center;
  }
  .sd-autofill {
    max-width: 250px;
  }
  .sd-autofill-div {
    display: flex;
    flex-direction: column;
    padding: 2px;
    margin: 10px;
    max-width: 240px;
  }
  .sd-autofill-div p {
    font-size: 14px;
    padding: 3px;
    word-wrap: normal;
    max-width: 240px;
  }
}
@media (max-width: 440px) {
  .sd-darshanam-box {
    padding: 0px 10px 0px 10px !important;
  }
  .spec {
    flex-direction: column;
    align-items: flex-start;
  }
  .size label p {
    margin-left: 6px !important;
  }
  .sd-accept {
    top: 38px;
  }
}
@media (max-width: 380px) {
  .sd-acc-calender .react-datepicker {
    /* width: 260px; */
    min-width: 240px !important;
  }
}
@media (max-width: 319px) {
  .wrapper {
    display: none;
  }
}

.sd-radio-select {
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  background: #fff;
}
.sd-radio-select:hover {
  border: 1px solid var(--headingTextColor);
}

.sd-radio-select-no-border {
  padding: 15px 0px 15px 0px;
  border-radius: 4px;
  background: #fff;
}

.sd-radio-select p,
.sd-radio-select-no-border p {
  margin-left: 30px;
  margin-bottom: 0px;
  font-size: 18px;
  color: var(--headingTextColor);
  text-transform: capitalize;
  font-weight: 500;
}

.sd-radio-select p span {
  margin-bottom: 0px;
  font-size: 16px;
  color: #181818;
}
.react-calendar {
  width: 250px;
}

.react-date-picker {
  width: 100%;
  background-color: #eaeaea;
  border: none;
  height: 100%;
}
.react-date-picker .react-date-picker__wrapper .react-datepicker-wrapper {
  border: none;
  padding-left: 15px;
}
.sd-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}
.sd-red
  .react-date-picker
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  background-color: white !important;
  border-radius: 4px;
  height: 100%;
}

.sd-red .react-date-picker {
  background-color: white;
  border-radius: 4px;
}

.sd-voilet .react-date-picker {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}

.sd-radio-select [type="radio"]:not(:checked) + label:after,
.sd-radio-select-no-border [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sd-radio-select [type="radio"]:checked + label:after,
.sd-radio-select-no-border [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sd-radio-select [type="radio"]:checked,
.sd-radio-select [type="radio"]:not(:checked),
.sd-radio-select-no-border [type="radio"]:checked,
.sd-radio-select-no-border [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sd-radio-select [type="radio"]:checked + label,
.sd-radio-select [type="radio"]:not(:checked) + label,
.sd-radio-select-no-border [type="radio"]:checked + label,
.sd-radio-select-no-border [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #666;
}
.mt--10 {
  margin-top: -20px !important;
  margin-left: 0px;
}
.sd-radio-select-no-border [type="radio"]:checked + label:before {
  content: " \25CF";
  position: absolute;
  left: 0;
  top: 0;

  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 24px/1 FontAwesome;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 30px;
  text-align: center;
}

.sd-radio-select [type="radio"]:checked + label:before {
  content: " \25CF";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 24px/1 FontAwesome;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 30px;
  text-align: center;
  margin-top: 13px;
}

.sd-radio-select-no-border [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
}

.sd-radio-select [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  margin-top: 13px;
}

.sd-radio-select-no-border [type="radio"] {
  background: #eaeaea !important;
  line-height: 55px;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none !important;
  width: 33px !important;
}

.sd-radio-select-no-borders [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 17px/1 FontAwesome;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 33px;
  text-align: center;
}
.size :checked + label:before {
  height: 20px !important;
  width: 20px !important;
  top: 4.5px !important;
  font: normal normal normal 20px/18px FontAwesome !important;
  line-height: 29px;
  margin-bottom: 0px;
}
.size :not(:checked) + label:before {
  top: 5.5px !important;
  height: 20px !important;
  width: 20px !important;
  margin-bottom: 0px;
}
.size label p {
  margin-left: 10px !important;
}
.size {
  padding: 5px 20px 5px 0px !important;
}
.size label {
  margin-bottom: 0px !important;
}

.sd-radio-select-no-borderss {
  padding: 0px !important;
  margin-left: 10px;
  border-radius: 4px;
  /* background: #fff; */
}

.sd-radio-select p,
.sd-radio-select-no-borderss p {
  margin-left: 30px;
  margin-bottom: 0px;
  font-size: 18px;
  color: var(--headingTextColor);
  text-transform: capitalize;
  font-weight: 500;
}

.sd-radio-select p span {
  margin-bottom: 0px;
  font-size: 16px;
  color: #181818;
}
.react-calendar {
  width: 250px;
}

.react-date-picker {
  width: 100%;
  background-color: #eaeaea;
  border: none;
  height: 100%;
}
.react-date-picker .react-date-picker__wrapper {
  border: none;
  padding-left: 15px;
}
.sd-voilet
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  background-color: white;
  border-radius: 4px;

  height: 100%;
}
.sd-red
  .react-date-picker
  .react-date-picker__wrapper
  .react-datepicker-wrapper
  .react-date-picker__inputGroup
  .react-datepicker__input-container
  input {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}

.sd-red .react-date-picker {
  background-color: white;
  border-radius: 4px;
}

.sd-voilet .react-date-picker {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}

.sd-radio-select [type="radio"]:not(:checked) + label:after,
.sd-radio-select-no-borderss [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sd-radio-select [type="radio"]:checked + label:after,
.sd-radio-select-no-borderss [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sd-radio-select [type="radio"]:checked,
.sd-radio-select [type="radio"]:not(:checked),
.sd-radio-select-no-borderss [type="radio"]:checked,
.sd-radio-select-no-borderss [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sd-radio-select [type="radio"]:checked + label,
.sd-radio-select [type="radio"]:not(:checked) + label,
.sd-radio-select-no-borderss [type="radio"]:checked + label,
.sd-radio-select-no-borderss [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #666;
}
.mt--10 {
  margin-top: -20px !important;
  margin-left: 0px;
}
.sd-radio-select-no-borderss [type="radio"]:checked + label:before {
  content: " \25CF";
  position: absolute;
  left: 0;
  top: 0;

  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 24px/1 FontAwesome;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 30px;
  text-align: center;
}

.sd-radio-select [type="radio"]:checked + label:before {
  content: " \25CF";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 24px/1 FontAwesome;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 30px;
  text-align: center;
  margin-top: 13px;
}

.sd-radio-select-no-borderss [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
}

.sd-radio-select [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* width: 18px;
  height: 18px; */
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  margin-top: 13px;
}

.sd-radio-select-no-borderss [type="radio"] {
  background: #eaeaea !important;
  line-height: 55px;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none !important;
  width: 33px !important;
}

.sd-radio-select-no-bordersss [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--headingTextColor);
  border-radius: 100%;
  background: var(--headingTextColor);
  font: normal normal normal 17px/1 FontAwesome;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 33px;
  text-align: center;
}
